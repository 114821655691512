import { Injectable } from '@angular/core';
import { GeneralService } from './general.service';
import { MessageService } from 'primeng/primeng';
import { HttpClient, HttpParams } from '@angular/common/http';
import { PersistenceService } from 'angular-persistence';
import { environment } from '../../environments/environment';
import { ResponseModel } from '../models';
import { Discount } from '../models/discount.model';

@Injectable()
export class DiscountService extends GeneralService {
  constructor(protected messageService: MessageService, protected httpClient: HttpClient, protected persistenceService: PersistenceService) {
    super(messageService, httpClient, persistenceService, environment.apiPostUrl, "discount");
  }
  async getDiscountByFilterAsync(categoryPaymentId: any, senderId: any, isSuccess: any, formDate: any, toDate: any, listPaymentId?: any): Promise<Discount[]> {
    let params = new HttpParams();
    params = params.append("categoryPaymentId", categoryPaymentId);
    params = params.append("senderId", senderId);
    params = params.append("isSuccess", isSuccess);
    params = params.append("formDate", formDate);
    params = params.append("toDate", toDate);
    if (listPaymentId) params = params.append("listPaymentId", listPaymentId);
    let res = await super.getCustomApi("GetDiscountByInfoPayment", params).toPromise();
    if (res.isSuccess) return res.data as Discount[];
    else return;
  }

  async getAllDiscountAsync(formDate?: any, toDate?: any, customerid?: any, pageSize?: any, pageNum?: any): Promise<ResponseModel> {
    let params = new HttpParams();
    if (formDate) params = params.append("fromDate", formDate);
    if (toDate) params = params.append("toDate", toDate);
    if (customerid) params = params.append("customerId", customerid);
    if (pageNum) params = params.append("pageNumber", pageNum);
    if (pageSize) params = params.append("pageSize", pageSize);

    return super.getCustomApi("GetAllDiscount", params).toPromise();
  }



}
